<template>
    <div class="main_footer pb-3">
        <footer class="footer_box ">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 order-3 order-md-1">
                        <div class="mb-lg-3 mb-2">
                            <router-link to="/"> <img src="../assets/images/logo.png" alt="logo" class="img-fluid">
                            </router-link>
                        </div>
                        <div class="pe-2">
                            <p>VALORALAND empowers individuals on their path to financial freedom and personal growth. Through Arbitrage, practical tools, and a supportive community, they provide a passive income, and investments. Join MayaVault to unlock your potential and redefine your financial future.</p>
                        </div>
                        <div class="social_icons">
                            <ul class="d-flex list-unstyled align-items-center">
                                <li> <a  href="https://www.facebook.com/profile.php?id=100093927331195"  target="_blank"><img src="../assets/images/footer/fb.png" alt="social-icon" class="me-lg-3 me-0"></a>
                                </li>
                                <li> <a href="https://www.instagram.com/mayavault1/" target="_blank"> <img src="../assets/images/footer/insta.png" alt="social-icon" class="me-lg-3 me-0" style="width:50px;"> </a>  
                                </li>
                                <li> <a href="https://twitter.com/MayaVault38029" target="_blank"> <img src="../assets/images/footer/twitter.png" alt="social-icon"
                                        class="me-lg-3 me-0"> </a> </li>
                                <li> <a href="https://t.me/mayavault1" target="_blank"><img src="../assets/images/footer/tele.png" alt="social-icon" class="me-lg-3 me-0"> </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2 col-xl-3 order-1 order-md-2">
                        <div class="footer_box mb-4">
                            <h3 class="text-uppercase">Helpful Navigation</h3>
                            <ul class="list-unstyled fw-light">
                                <li>
                                    <router-link to="/"> Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/login">Login</router-link>
                                </li>
                                <li>
                                    <router-link to="/signin">Get Started</router-link>
                                </li>
                                <li>
                                    <router-link to=""> About Us</router-link>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-2 col-xl-3 order-2 order-md-3">
                        <div class="footer_box mb-4 ">
                            <h3 class="text-uppercase">Services & Terms</h3>
                            <ul class="list-unstyled">

                                <li>
                                     <a href="/MayaVault_terms.pdf" target="_blank" class="text-decoration-none">Terms & Conditions</a>
                                </li>
                                <li>
                                     <a href="/MayaVault_policy.pdf" target="_blank" class="text-decoration-none">Privacy Policy</a>
                                </li>
                         

                            </ul>
                        </div>
                
                    </div>
                </div>

            </div>
        </footer>
        <div class="social-icons pt-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="text-center copy-right">
                            <img src="../assets/images/footer/border.png" alt="image" class="img-fluid mb-4">
                            <p> &copy; Copyright <router-link to="/" class="text-decoration-none">@2023 Volarland .com
                                </router-link> All Rights Reserved </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import PrivacyPolicy from "@/assets/images/pdf/PrivacyPolicy.pdf";
// import TermsandCondition from "@/assets/images/pdf/TermandCondition.pdf"
export default {
    data(){
        return{
//             t_c:TermsandCondition,
// privacy_policy:PrivacyPolicy
        }
    }

}
</script>

<style scoped>
.main_footer {
    background: var(--black);
    /* background: url(../assets/images/footer/bg.png); */
    padding: 70px 0;
}

.footer_box h3 {
    color: #c8057c;
    font-size: 17px;
    font-weight: 400;
}

.footer_box p {
    color: var(--white);
}

.footer_box ul li {
    padding: 6px;
}

.footer_box ul li a {
    color: var(--grey);
    text-decoration: none;
    font-weight: 500;
}

.footer_box ul li:hover a {
    color: #c8057c;
    transform: translate(0, 20px);
}

.copy-right p {
    color: var(--white);
}

.copy-right a {
    color: #c8057c;
}

/* ================================
            MEDIA QUERY
 ================================ */

@media all and (min-width:320px) and (max-width:576px) {
    .footer_box h3 {
        font-size: 15px;
        font-weight: 500;
    }
    .main_footer{
        padding:40px 0;
    }
}
</style>
